import { Routes, Route, Navigate } from "react-router-dom";
import PropertyList from "../views/PropertyList.js";
import Login from "../views/Login";
import PaymentStatusPage from "../views/PaymentStatusPage.js";
import UploadForm from "../views/UploadForm.js"
import UploadPhotosforExistingProperty from "../views/UploadPhotosforExistingProperty.js"

// const PropertyList = lazy(() => import("../views/PropertyList.js"));
// const Login = lazy(() => import("../views/Login"));

// Function to wrap routes with the ProtectedRoute component
// const wrapRoutesWithProtection = (routes, redirectTo) => {
//   return routes.map((route) => {
//     // Wrap each route with ProtectedRoute
//     return {
//       ...route,
//       element: (
//         <ProtectedRoute
//           element={route.element}
//           redirectTo={redirectTo}
//           key={route.path} // Add a unique key to avoid React warnings
//         />
//       ),
//     };
//   });
// };
// const ThemeRoutes = [
//   {
//     path: "/",
//     children: wrapRoutesWithProtection([
//       { path: "/", element: <Navigate to="/properties" /> },
//       { path: "/Login", exact: true, element: <Login /> },
//     ], '/properties'),
//   }, { path: "/properties", element: <PropertyList /> },
// ];

const ThemeRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/properties" />} />
    <Route path="/Login" element={<Login />} />
    <Route path="/properties" element={<PropertyList />} />
    <Route path="/payment-success" element={<PaymentStatusPage />} />
    <Route path="/v1/photo-upload/vdihb/team-citynect" element={<UploadForm />} />
    <Route path="/v2/for-existing-property/upload-photos/sakmx/cwjwfbskhjmibsdjb" element={<UploadPhotosforExistingProperty />} />
  </Routes>
);

export default ThemeRoutes;
