import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import { faBars, faPhone, faX } from "@fortawesome/free-solid-svg-icons";
import "./demo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Button } from "reactstrap";
const Navbar = ({ user }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <nav className="navbar shadow-sm sticky-top ">
      <div className="whatsapp-button">
        <Link
          target="_blank"
          rel="noreferrer"
          to="https://api.whatsapp.com/send?phone=+919316066832&text=Hello%20there,%20I%20have%20a%20question%20for%20you."
          className=""
        >
          <div className="icon-flex">
            {/* <p>Help</p> */}
            <FontAwesomeIcon className="whats-icon" icon={faWhatsapp} />
          </div>
        </Link>
      </div>
      <div className="Navcontainer">
        <div className="logo">
          <Link id="logoBox" className="navbar-brand" to="/">
            <img
              src="/images/logo.png"
              width={"500px"}
              alt="logo"
              className="mobileAndTab-hide"
            />
            <span className="navbar-text">citynect</span>
          </Link>
        </div>
        <div className="menu-icon rounded" onClick={handleShowNavbar}>
          {showNavbar ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          {/* For Mobile View Only */}
          <ul>
            <li onClick={handleShowNavbar}>
              <a
                href="https://api.whatsapp.com/send?phone=+917567712993&text=Hello%2C%20I%27m%20interested%20in%20your%20plan."
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link"
              >
                <FontAwesomeIcon icon={faPhone} />
                <span>9737678347</span>
              </a>
            </li>
            <li onClick={handleShowNavbar}>
              <a
                href="https://api.whatsapp.com/send?phone=+917567712993&text=Hello%2C%20I%27m%20interested%20in%20your%20plan."
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link"
              >
                <FontAwesomeIcon icon={faPhone} />
                <span>7567712993</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
