import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleModal from "./SimpleModal";
import "./PropertyList.css";
import ModalContent from "./ModalContent";

const userId = Cookies.get("id");

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    content: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const bottomBoundaryRef = useRef();
  const [selectedCity, setSelectedCity] = useState(null);
  const [furnishedTypeFilter, setFurnishedTypeFilter] = useState(null);
  const [areaFilter, setAreaFilter] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Define dropdown state
  const [dropdownOpen1, setDropdownOpen1] = useState(false); // Define dropdown state
  const [dropdownOpen2, setDropdownOpen2] = useState(false); // Define dropdown state

  useEffect(() => {
    fetchProperties();
  }, [selectedCity, furnishedTypeFilter, areaFilter]);

  useEffect(() => {
    const handleScroll = () => {
      if (isLoading || !hasMore || !bottomBoundaryRef.current) return;
      if (
        bottomBoundaryRef.current.getBoundingClientRect().bottom <=
        window.innerHeight
      ) {
        fetchProperties();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore, selectedCity, furnishedTypeFilter, areaFilter]);

  const fetchProperties = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://premium.citynect.in/user/properties/filter?page=${currentPage}&size=100`,
        {
          userId: userId,
          city: selectedCity,
          furnishedType: furnishedTypeFilter,
          area: areaFilter,
        }
      );
      setProperties((prevProperties) => [
        ...prevProperties,
        ...response.data.data.properties,
      ]);
      setCurrentPage((prevPage) => prevPage + 1);
      setHasMore(response.data.data.properties.length > 0);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
    setIsLoading(false);
  };

  // const handleCityFilter = (city) => {
  //   setSelectedCity((prevCity) => (prevCity === city ? null : city));
  //   setProperties([]); // Clear properties when city filter changes
  //   setCurrentPage(0);
  //   setHasMore(true);
  // };

  const handleCityFilter = (city) => {
    setSelectedCity(city);
    setProperties([]); // Clear properties when area filter changes
    setCurrentPage(0);
    setHasMore(true);
  };

  // const handleAreaFilter = (event, { newValue }) => {
  //   setAreaFilter(newValue);
  //   setInputValue(newValue); // Update inputValue to reflect the selected suggestion
  //   setProperties([]);
  //   setCurrentPage(0);
  //   setHasMore(true);
  // };

  const handleAreaFilter = (areaValue) => {
    setAreaFilter(areaValue);
    setProperties([]); // Clear properties when area filter changes
    setCurrentPage(0);
    setHasMore(true);
  };

  const handleFurnishedFilter = (furnishedValue) => {
    setFurnishedTypeFilter(furnishedValue);
    setProperties([]); // Clear properties when area filter changes
    setCurrentPage(0);
    setHasMore(true);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const toggleDropdown1 = () => {
    setDropdownOpen1((prevState) => !prevState);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const handleContactOwner = async (propertyId) => {
    if (!userId) {
      navigate("/Login");
      return; // Make sure to return or use another way to exit the function
    }
    try {
      const response = await axios.post(
        "https://premium.citynect.in/user/contacted",
        {
          userId: userId,
          propertyId: propertyId,
        }
      );

      if (response.status === 200) {
        const updatedProperties = properties.map((property) =>
          property.id === propertyId
            ? {
                ...property,
                number: response.data.number,
                address: response.data.address,
              }
            : property
        );
        setProperties(updatedProperties);
        // Assuming the property details are in the response.data and you want to display them
        const propertyDetails = response.data;
        setModal({
          isOpen: true,
          title: "Property Contacted Successfully",
          content: (
            <div>
              <p>Name: {propertyDetails.name}</p>
              <p>Number: {propertyDetails.number}</p>
              <p>Size: {propertyDetails.title}</p>
              <p>Type: {propertyDetails.type}</p>
              <p>Rent: {propertyDetails.rent}</p>
              {/* <p>Description: {propertyDetails.description}</p> */}
              <p>Address: {propertyDetails.address}</p>
              <p>Area: {propertyDetails.area}</p>
              <p>Furnished Type: {propertyDetails.furnishedType}</p>
              <p>
                Listed On:{" "}
                {`${propertyDetails.days.split("-")[2]}-${
                  propertyDetails.days.split("-")[1]
                }-${propertyDetails.days.split("-")[0]}`}
              </p>
              {/* Add more property details as needed */}
            </div>
          ),
        });
      }
    } catch (error) {
      if (!error.response) {
        console.error("Error contacting owner:", error);
        return;
      }

      switch (error.response.status) {
        case 422:
          setModal({
            isOpen: true,
            content: (
              <ModalContent text="Please buy premium to contact the owner." />
            ),
          });
          break;
        case 412:
          setModal({
            isOpen: true,
            content: (
              <ModalContent text="You have reached your contact limit." />
            ),
          });
          break;
        case 500:
          setModal({
            isOpen: true,
            title: "Error",
            content: <p>An internal error occurred. Please try again later.</p>,
          });
          break;
        default:
          console.error("An unexpected error occurred:", error);
      }
    }
  };

  return (
    <div className="property-list bg">
      <div className="property-list-container">
        <div className="property-list-header">
          <div className="">
            <p className="text-center property-title">
              End Your Home Search Struggle, Just a Click Away !
            </p>
            <p className="text-center property-description">
              Explore thousands of properties
              <span style={{ color: "#00B090" }}> Brokerage Free</span>
            </p>
          </div>
          <div className="filters">
            {/* <p>Select city : </p> */}
            {/* <Button
              onClick={() => handleCityFilter("Ahmedabad")}
              color="btn btn-outline-primary button-set"
              className={selectedCity === "Ahmedabad" ? "selected" : ""}
            >
              Ahmedabad
            </Button>
            <Button
              onClick={() => handleCityFilter("Gandhinagar")}
              color="btn btn-outline-primary button-set gandhinagar"
              className={selectedCity === "Gandhinagar" ? "selected" : ""}
            >
              Gandhinagar
            </Button> */}
            <Dropdown
              className=""
              isOpen={dropdownOpen2}
              toggle={toggleDropdown2}
            >
              <DropdownToggle caret>
                {selectedCity ? `City: ${selectedCity}` : "City"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleCityFilter("")}>
                  All Types
                </DropdownItem>
                <DropdownItem onClick={() => handleCityFilter("Chandigarh")}>
                  Chandigarh
                </DropdownItem>
                <DropdownItem onClick={() => handleCityFilter("Mohali")}>
                  Mohali
                </DropdownItem>
                <DropdownItem onClick={() => handleCityFilter("Zirakpur")}>
                  Zirakpur
                </DropdownItem>
                <DropdownItem onClick={() => handleCityFilter("Kharar")}>
                  Kharar
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleCityFilter("Sahibzada Ajit Singh Nagar")}
                >
                  Sahibzada Ajit Singh Nagar
                </DropdownItem>
                <DropdownItem onClick={() => handleCityFilter("Panchkula")}>
                  Panchkula
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <div className="dropdown-row">
              <Dropdown
                className="dropdown1"
                isOpen={dropdownOpen1}
                toggle={toggleDropdown1}
              >
                <DropdownToggle caret>
                  {furnishedTypeFilter
                    ? `Furnished: ${furnishedTypeFilter}`
                    : "Furnishing"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleFurnishedFilter("")}>
                    All Types
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleFurnishedFilter("Furnished")}
                  >
                    Furnished
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleFurnishedFilter("Fix-Furnished")}
                  >
                    Fix-Furnished
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleFurnishedFilter("Semi Furnished")}
                  >
                    Semi Furnished
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleFurnishedFilter("Unfurnished")}
                  >
                    Unfurnished
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                className="dropdown2"
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                direction="down"
              >
                <DropdownToggle caret>
                  {areaFilter ? `Area: ${areaFilter}` : "Select Area"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleAreaFilter("")}>
                    All Areas
                  </DropdownItem>
                  {Array.from(
                    new Set(properties.map((property) => property.area))
                  ).map((area, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => handleAreaFilter(area)}
                    >
                      {area}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              {/* <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
              /> */}
            </div>
          </div>
        </div>
        <p className="ms-5">8000+ Properties Found</p>
        {window.innerWidth >= 767 ? (
          <Table className="" bordered hover responsive>
            <thead id="heading-background">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Number</th>
                <th>Size</th>
                <th>Type</th>
                <th>Rent</th>
                {/* <th>Description</th> */}
                <th>Address</th>
                <th>Area</th>
                <th>Furnishing</th>
                <th>ListedOn</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property, index) => (
                <tr key={property.id}>
                  <td>{index + 1}</td>
                  <td>{property.name}</td>
                  <td>
                    {property.number === "-" ? (
                      <Button
                        color="primary"
                        onClick={() => handleContactOwner(property.id)}
                      >
                        Contact Owner
                      </Button>
                    ) : (
                      property.number
                    )}
                  </td>
                  <td>{property.title}</td>
                  <td>{property.type}</td>
                  <td>{property.rent}</td>
                  {/* <td>{property.description}</td> */}
                  <td>{property.address}</td>
                  <td>{property.area}</td>
                  <td>{property.furnishedType}</td>
                  {/* <td>{property.days}</td> */}
                  <td>{`${property.days.split("-")[2]}-${
                    property.days.split("-")[1]
                  }-${property.days.split("-")[0]}`}</td>
                </tr>
              ))}
              <tr ref={bottomBoundaryRef}></tr>
            </tbody>
          </Table>
        ) : (
          properties.map((property, index) => (
            <div key={property.id} className="property-item card">
              <div className="card-body">
                <div className="property-details">
                  <p>
                    <strong>Name:</strong> {property.name}
                  </p>
                  <p>
                    <strong>Size:</strong> {property.title}
                  </p>
                  <p>
                    <strong>Type:</strong> {property.type}
                  </p>
                  {/* <p>
                    <strong>Description:</strong> {property.description}
                  </p> */}
                  <p>
                    <strong>Address:</strong> {property.address}
                  </p>
                  <p>
                    <strong>Area:</strong> {property.area}
                  </p>
                  <p>
                    <strong>Rent:</strong> {property.rent}
                  </p>
                  <p>
                    <strong>Furnished Type:</strong> {property.furnishedType}
                  </p>
                  <p>
                    <strong>Listed On:</strong>{" "}
                    {new Date(property.days).toLocaleDateString("en-GB")}
                  </p>
                </div>
                {property.number === "-" ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => handleContactOwner(property.id)}
                  >
                    Contact Owner
                  </button>
                ) : (
                  <div className="contact-info">
                    <strong>Contact:</strong> {property.number}
                  </div>
                )}
              </div>
              <tr ref={bottomBoundaryRef}></tr>
            </div>
          ))
        )}
      </div>
      {isLoading && <p>Loading more properties...</p>}
      <SimpleModal
        isOpen={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
        title={modal.title}
      >
        {modal.content}
      </SimpleModal>
    </div>
  );
};

export default PropertyList;
