// src/components/UploadForm.js

import React, { useState } from "react";
import axios from "axios";
import "./UploadForm.css"; // Import the CSS file

const UploadForm = () => {
  const [number, setNumber] = useState("");
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 10) {
      setError({ message: "You can upload a maximum of 10 files." });
      setFiles([]);
      setPreviews([]);
    } else {
      setFiles(selectedFiles);
      setError(null);
      const newPreviews = selectedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviews(newPreviews);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!/^\d{10}$/.test(number)) {
      setError({ message: "Number must be exactly 10 digits." });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("number", number);
    for (let i = 0; i < files.length; i++) {
      formData.append("photos", files[i]);
    }

    try {
      const res = await axios.post(
        `https://auth.citynect.in/app/citynect-protected/jidfigufh/fhigfyfeg/ckodih/v1/upload-photos/by-admin/${number}/fuhdbfhu`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setResponse(res.data);
      setError(null);
      setPreviews([]);
      setFiles([]);
    } catch (err) {
      const errorData = err.response
        ? err.response.data
        : { message: "Error uploading files" };
      setError(errorData);
      setResponse(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <h2>Upload Photos/Videos</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Number:</label>
            <input
              type="text"
              value={number}
              onChange={handleNumberChange}
              required
              pattern="\d{10}"
              title="Number must be exactly 10 digits."
            />
          </div>
          <div className="form-group">
            <label>Upload Photos/Videos:</label>
            <input type="file" multiple onChange={handleFileChange} required />
          </div>
          {previews.length > 0 && (
            <div className="previews">
              {previews.map((preview, index) => (
                <div key={index} className="preview">
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    &times;
                  </button>
                  {files[index].type.startsWith("image") ? (
                    <img src={preview} alt={`preview ${index}`} />
                  ) : (
                    <video src={preview} controls />
                  )}
                </div>
              ))}
            </div>
          )}
          <button type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Submit"}
          </button>
        </form>
        {response && (
          <div className="message success">
            <div>Data: {response.data}</div>
          </div>
        )}
        {error && (
          <div className="message error">
            <div>Error: {error.message}</div>
            {error.status && <div>Status: {error.status}</div>}
            {error.timestamp && (
              <div>Timestamp: {new Date(error.timestamp).toLocaleString()}</div>
            )}
            {error.path && <div>Path: {error.path}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadForm;
