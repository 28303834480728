import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const Navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State to hold error message

  useEffect(() => {
    if (Cookies.get("id")) {
      Navigate("/properties");
    }
    // eslint-disable-next-line
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (number.length !== 10) {
      setError("Contact number must be 10 digits.");
      return; // Return early if the number is not 10 digits
    }
    setError(""); // Clear any previous errors
    fetch("https://premium.citynect.in/user/login/hifbeudh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ number: number, password: password }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json(); // Parse the response JSON
        } else {
          //   alert("Check Credentials");
          throw new Error("Invalid credentials"); // Throw an error to skip the next then block
        }
      })
      .then((data) => {
        // Save the 'id' in a cookie permanently
        Cookies.set("id", data.id, {
          expires: new Date("9999-12-31T23:59:59"), // Set to expire far in the future
        });

        window.location.reload();
        Navigate("/properties");
      })
      .catch((err) => {
        alert(err);
      });
  };
  const handleNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Allow only numeric input
    const truncatedInput = input.slice(0, 10); // Limit to 10 digits
    setNumber(truncatedInput);
    setError(""); // Clear any previous errors
  };
  return (
    <div>
      <div className="bg">
        <section className="vh-100 d-flex justify-content-center align-items-center">
          <div className="container-fluid h-custom ">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                <form className="border p-5 bg-white" onSubmit={handleSubmit}>
                  <div className="form-outline mb-4">
                    <p className="text-center custom-heading">
                      <b>Create Your Account</b>
                    </p>
                    <p className="text-center">
                      Register with your mobile number and password
                    </p>
                    <label className="form-label" htmlFor="number">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      id="number"
                      className="form-control form-control-lg"
                      required
                      value={number}
                      onChange={handleNumberChange}
                      placeholder="Enter a valid Number"
                    />
                    {error && <div className="text-danger">{error}</div>}{" "}
                    {/* Display error message if present */}
                  </div>
                  <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="form3Example4">
                      Password
                    </label>
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control form-control-lg"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Create your password"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success btn-lg"
                    style={{
                      paddingLeft: "2.5rem",
                      paddingRight: "2.5rem",
                      width: "100%",
                    }}
                  >
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
